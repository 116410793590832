import PropTypes from 'prop-types'
import { MenuContextProvider } from '@/context/MenuContext'
import Footer from '@/components/shared/footer'
import CornerNav from '@/components/navigation/corner-nav'
import GlobalNav from '@/components/navigation/global-nav'
import MobileNavMenu from '@/components/navigation/mobile-nav/MobileNavMenu'

const ErrorLayout = ({ children }) => (
  <MenuContextProvider initialState={{ hasBackgroundColor: true }}>
    <GlobalNav />
    {children}
    <Footer />
    <MobileNavMenu />
    <CornerNav />
  </MenuContextProvider>
)

ErrorLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default ErrorLayout
