import { Component } from 'react'
import PropTypes from 'prop-types'
import { captureError } from 'lib/logger'
import ErrorLayout from '@/components/generic/layouts/ErrorLayout'
import NotFound from '@/components/error-pages/404'
import InternalServerError from '@/components/error-pages/500'
import ENTITY_TYPE from '@/enums/entity-type'
import {
  RequestStatusContext,
  STATUS_CODES,
} from '@/components/request-logging'

class ErrorPage extends Component {
  static getInitialProps({ err, res, xhr }) {
    const statusCode = err?.statusCode || res?.statusCode || xhr?.status || null

    if (err?.statusCode && res) {
      res.statusCode = err.statusCode
    }

    captureError(err)

    return { entityType: err?.entityType, statusCode }
  }

  render() {
    const statusCode =
      this.props.statusCode || STATUS_CODES.INTERNAL_SERVER_ERROR
    let ErrorComponent

    if (statusCode === STATUS_CODES.NOT_FOUND) {
      ErrorComponent = (
        <ErrorLayout>
          <NotFound entityType={this.props.entityType} />
        </ErrorLayout>
      )
    } else {
      ErrorComponent = <InternalServerError />
    }

    return (
      <RequestStatusContext.Consumer>
        {({ setStatusCode }) => {
          setStatusCode(statusCode)
          return ErrorComponent
        }}
      </RequestStatusContext.Consumer>
    )
  }
}

ErrorPage.propTypes = {
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)),
  statusCode: PropTypes.number,
}

export default ErrorPage
